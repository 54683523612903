<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'express_allOrders',
          'express_allOrdersSub',
          'express_DHLSub',
          'express_fedexSub',
          'express_tntSub',
        ])"></div>

      <div class="content-tabs content-tabs--separator"
           v-if="Object.keys($store.getters.getUserProfile).length > 0"
           v-bind:class="{'loading-tabs': $store.getters.getExpressLoading === true,}">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index + loadUserRoleAndAuthorizedData"
               v-bind:class="{active: item.active}"
               v-if="checkUserFromSNGGroup(item) && checkDHLExpressUserFromGB(item.id) && checkUkrainianUserInTNT(item.id) && item.permissionVisible"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              <template v-if="item.ico">
                <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
              </template>
              <template v-else>
                {{ item.label }}
              </template>
            </div>
            <span class="content-tabs__item-small">
              {{ item.subLabel }}
            </span>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
            :count="countFilterParams"
            :showFilter="showFilter"
            @toggleFilter="$emit('toggleFilter')"
        />
      </div>
    </div>

    <div class="light-bg-block" style="flex-wrap: wrap;">
      <div class="btn-left-block">
        <DefaultCheckbox
            :label="$t('warehouseManagement_selectAll.localization_value.value')"
            @input="(val) => $emit('selectAll', val)"
        />
      </div>
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'shops_addNew',
          'express_labels',
        ])"></div>
        <div class="d-flex align-items-center"
             v-if="!isAdmin && _.has(getCurrentUserAlternativeContact, 'country_id')"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['express_AlternativeAddress',])"></div>
          <DefaultCheckbox
              :value="isUseAlternativeContact"
              :disabled="true"
          />
            {{$t('express_AlternativeAddress.localization_value.value')}}
          <LinkButton
              class="mr-3 ml-2"
              :type="'edit'"
              @click.native="isModalAddressEditingPopup = true"
          />
        </div>
        <span v-if="navTabs.all.active && !isLatvian">
          <MainButton class="btn-fit-content"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
                      @click.native="showChooseExpressOrderPopup"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </span>
        <router-link
            v-else-if="!((this.isPoland || this.isUserAlternativeContactPoland) && navTabs[DHL_DELIVERY_SERVICE.name].active) && !navTabs[NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name].active"
            :to="getNewOrderLink">
          <MainButton class="btn-fit-content"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>
        <router-link :to="$store.getters.GET_PATHS.expressLabels"
                     v-if="_.has(this.currentPermissions, PERMISSIONS.ORDER_EXPRESS_VIEW_BLOCKED_DOCS)"
                     class="ml-3"
        >
          <MainButton class="btn-fit-content"
                      :value="$t('express_labels.localization_value.value')"
          >
          </MainButton>
        </router-link>
        <!--        <router-link-->
        <!--                :to="$store.getters.GET_PATHS.expressOrderCreation + 'dhl'"-->
        <!--                v-if="navTabs[DHL_DELIVERY_SERVICE.name].active">-->
        <!--          <MainButton class="btn-fit-content"-->
        <!--                      :value="$t('shops_addNew.localization_value.value')"-->
        <!--                      :ico="'ico'"-->
        <!--                      @click.native="showChooseExpressOrderPopup"-->
        <!--          >-->
        <!--            <template slot="ico">-->
        <!--              <PlusIconSVG class="btn-icon-plus" />-->
        <!--            </template>-->
        <!--          </MainButton>-->
        <!--        </router-link>-->
        <!--        <router-link-->
        <!--                v-if="navTabs[FEDEX_DELIVERY_SERVICE.name].active"-->
        <!--                :to="$store.getters.GET_PATHS.expressOrderCreation + 'fedex'"-->
        <!--        >-->
        <!--          <MainButton class="btn-fit-content"-->
        <!--                      :value="$t('shops_addNew.localization_value.value')"-->
        <!--                      :ico="'ico'"-->
        <!--                      @click.native="showChooseExpressOrderPopup"-->
        <!--          >-->
        <!--            <template slot="ico">-->
        <!--              <PlusIconSVG class="btn-icon-plus" />-->
        <!--            </template>-->
        <!--          </MainButton>-->
        <!--        </router-link>-->
        <!--        <router-link-->
        <!--                v-if="navTabs[TNT_DELIVERY_SERVICE.name].active"-->
        <!--                :to="$store.getters.GET_PATHS.expressOrderCreation + 'tnt'"-->
        <!--        >-->
        <!--          <MainButton class="btn-fit-content"-->
        <!--                      :value="$t('shops_addNew.localization_value.value')"-->
        <!--                      :ico="'ico'"-->
        <!--                      @click.native="showChooseExpressOrderPopup"-->
        <!--          >-->
        <!--            <template slot="ico">-->
        <!--              <PlusIconSVG class="btn-icon-plus" />-->
        <!--            </template>-->
        <!--          </MainButton>-->
        <!--        </router-link>-->
      </div>
    </div>


    <ChooseExpressOrderPopup
        @close="closeChooseExpressOrderPopup"
        v-if="isModalChooseExpressOrderPopup"
        :dhl="checkCreateDHL()"
        :fedex="checkCreateFedex()"
        :tnt="checkCreateTNT()"
        :npg="checkCreatePoshta()"
    />

    <AddressEditing
        @close="isModalAddressEditingPopup = false"
        v-if="isModalAddressEditingPopup"
    />

  </div>
</template>

<script>
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
import ChooseExpressOrderPopup
  from "../../../../../../coreComponents/Popups/ChooseExpressOrder/ChooseExpressOrderPopup";
import {
  DHL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE
} from "../../../../../../../staticData/staticVariables";
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import AddressEditing from "@/components/modules/ProfileModule/popups/AddressEditing/AddressEditing";


export default {
  name: "ExpressOrderTableUserHead",
  components: {
    LinkButton,
    MainButton,
    ToggleFilterButton,
    DefaultCheckbox,
    PlusIconSVG,
    ChooseExpressOrderPopup,
    AddressEditing,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
    navTabs: Object,
  },

  computed: {
    getNewOrderLink() {

      if (this.isLatvian) {
        return this.$store.getters.GET_PATHS.expressOrderCreation + 'fedex'
      }

      if (this.navTabs[DHL_DELIVERY_SERVICE.name].active) {
        return this.$store.getters.GET_PATHS.expressOrderCreation + 'dhl'
      }
      if (this.navTabs[FEDEX_DELIVERY_SERVICE.name].active) {
        return this.$store.getters.GET_PATHS.expressOrderCreation + 'fedex'
      }
      // if (this.navTabs[TNT_DELIVERY_SERVICE.name].active) {
      //   return this.$store.getters.GET_PATHS.expressOrderCreation + 'tnt'
      // }
      console.log(this.navTabs);
      if (this.navTabs[NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name].active) {
        return this.$store.getters.GET_PATHS.expressOrderCreation + NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name
      }

      return false
    },
  },

  data() {
    return {
      isModalChooseExpressOrderPopup: false,
      isModalAddressEditingPopup: false,
      DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
      FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
      TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
      NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE
    }
  },

  methods: {

    checkCreateDHL() {
      if (this.checkUserFromSNGGroup(DHL_DELIVERY_SERVICE) &&
          this.checkDHLExpressUserFromGB(DHL_DELIVERY_SERVICE.id) &&
          !this.isPoland && !this.isUserAlternativeContactPoland &&
          this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXPRESS_DHL)) {
        return '/create/dhl'
      }
      return null
    },

    checkCreateTNT() {
      if (this.checkUkrainianUserInTNT(TNT_DELIVERY_SERVICE.id) &&
          this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXPRESS_TNT)) {
        return '/create/tnt'
      }
      return null
    },

    checkCreateFedex() {
      if (this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXPRESS_FEDEX)) {
        return '/create/fedex'
      }
      return null
    },

    checkCreatePoshta() {
      return null
      // /**
      //  * if NOVAPOSHTA GLOBAL and No From Ukraine -> null
      //  */
      // if(!this.isAdmin && !this.isUserUkraineOrAlternativeContactUkraine) {
      //   return null
      // }
      //
      // if (this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXPRESS_NPG)) {
      //   return '/create/' + NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name
      // }
      // return null
    },

    checkUserFromSNGGroup(item) {
      if (this.isAdmin) return true

      let user = this.$store.getters.getUserProfile,
          group = user.user_personal_contact?.country_group?.fromSNGGroup

      return !(group && (item.id === DHL_DELIVERY_SERVICE.id || !item.id))
    },

    showChooseExpressOrderPopup() {
      this.isModalChooseExpressOrderPopup = true
    },

    closeChooseExpressOrderPopup() {
      this.isModalChooseExpressOrderPopup = false
    },
  }
}
</script>

<style scoped>

</style>
